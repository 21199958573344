import React from 'react'

import '../../static/mobNav.css'

const MobNav = ({onItemClick, activeItem, onKnowMoreClick}) => {

    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
      };
    return (
        <div style={{ width: "100%", height: "100px", backgroundColor: "#f5a201",marginTop:"50px"}} className="mobNav">
            <ul style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%", width: "100%", listStyleType: "none" }}>
                <li style={{display:"flex", flexDirection:"column", alignItems:"center",justifyContent:"center", height:"100%", padding:"10px 10px", paddingTop:"30px"}}
                onClick={() => { scrollToTop(); }}
                >
                <i className="fa-solid fa-house-user" style={{fontSize:"40px", color:"#000"}}></i>
                    <p style={{fontWeight:"500",color:"#fff", textShadow:"1px 1px 0.5px #000"}}>Home</p>
                </li>
                <li style={{display:"flex", flexDirection:"column", alignItems:"center",justifyContent:"center", height:"100%", padding:"10px 10px", paddingTop:"30px"}}
                onClick={() => onItemClick(1)} className={activeItem === 1 ? 'mobActive' : ''}
                >
                <i className="fa-solid fa-person" style={{fontSize:"40px", color:"#000"}}></i>
                    <p style={{fontWeight:"500",color:"#fff", textShadow:"1px 1px 0.5px #000"}}>About</p>
                </li>
                <li style={{display:"flex", flexDirection:"column", alignItems:"center",justifyContent:"center", height:"100%", padding:"10px 10px", paddingTop:"30px"}}
                onClick={() => onItemClick(2)} className={activeItem === 2 ? 'mobActive' : ''}
                >
                <i className="fa-solid fa-briefcase" style={{fontSize:"40px", color:"#000"}}></i>
                    <p style={{fontWeight:"500",color:"#fff", textShadow:"1px 1px 0.5px #000"}}>Resume</p>
                </li>
                {/* <li style={{display:"flex", flexDirection:"column", alignItems:"center",justifyContent:"center", backgroundColor:"white", height:"100%", padding:"10px 10px", paddingTop:"30px"}}
                onClick={() => onItemClick(4)} className={activeItem === 4 ? 'mobActive' : ''}
                >
                <i className="fa-solid fa-pen-nib" style={{fontSize:"40px", color:"#f5a201"}}></i>
                    <p style={{fontWeight:"500",color:"#f5a201", textShadow:"1px 1px 0.5px #000"}}>Review</p>
                </li> */}
                <li style={{display:"flex", flexDirection:"column", alignItems:"center",justifyContent:"center", backgroundColor:"white", height:"100%", padding:"10px 10px", paddingTop:"30px", marginRight:"30px"}}
                 onClick={onKnowMoreClick}
                >
                <i className="fa-solid fa-circle-plus" style={{fontSize:"40px", color:"#f5a201"}}></i>
                    <p style={{fontWeight:"500",color:"#f5a201", textShadow:"1px 1px 0.5px #000"}}>More</p>
                </li>
            </ul>
        </div>
    )
}

export default MobNav
