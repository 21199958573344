import React from 'react'
import logoImg from '../../static/assets/profile.jpeg'

const LogoImg = () => {
  return (
    <div className='navbar-logo-img' style={{width:"100%", height:"100%", display:"flex", alignItems:"center", justifyContent:"center"}}>
      <img src={logoImg} alt="Profile Logo" style={{height:"110%", width:"100%", aspectRatio:"1/1"}} />
    </div>
  )
}

export default LogoImg
