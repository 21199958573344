import React from 'react'

import '../../static/resume.css'

const Resume = () => {
  return (
    <div className='my-resume container'>
      <div className="resume-header">
        <h2>Experience</h2>
      </div>
      <div className="container my-2">
        <div className="row mx-5">
          <div className="col-sm">
            <div className="card my-5" style={{ width: "20rem", height: "15rem" }}>
              <div className="card-body">
                <h3 className="card-title" style={{ fontFamily: "Bebas Neue", fontSize: "38px", textAlign: "center", color: "#f5a201", fontWeight: "500" }}>Freelancer</h3>
                <p className="card-text text-center" style={{ fontFamily: "Dosis", fontWeight: "700", fontSize: "24px" }}>5+ Years.</p>
                <p style={{ fontSize: "12px", textAlign: "center" }}>As a freelance web developer, I've flourished for five years, mastering diverse technologies, collaborating with clients, and crafting dynamic digital solutions.</p>
              </div>
            </div>
          </div>
          <div className="col-sm">
            <div className="card my-5" style={{ width: "20rem", height: "15rem" }}>
              <div className="card-body text-center">
                <h3 className="card-title" style={{ fontFamily: "Bebas Neue", fontSize: "38px", textAlign: "center", color: "#f5a201", fontWeight: "500" }}>Ask Python</h3>
                <p className="card-text text-center" style={{ fontFamily: "Dosis", fontWeight: "700", fontSize: "20px" }}> Content Writer | 2 Months</p>
                <p style={{ fontSize: "14px", textAlign: "center" }}>You can read the articles written by me from the link below.</p>
                <a href="" style={{textDecoration:"none", color:"#f5a201", fontSize:"18px"}}>Link to articles</a>
              </div>
            </div>
          </div>
          <div className="col-sm">
            <div className="card my-3" style={{ width: "20rem", height: "15rem" }}>
              <div className="card-body">
              <h3 className="card-title" style={{ fontFamily: "Bebas Neue", fontSize: "38px", textAlign: "center", color: "#f5a201", fontWeight: "500" }}>Coding Zen</h3>
                <p className="card-text text-center" style={{ fontFamily: "Dosis", fontWeight: "700", fontSize: "22px" }}>Python + Web Dev Instructor</p>
                <p style={{ fontSize: "12px", textAlign: "center" }}>As a Python and web development teacher, I've dedicated years to sharing expertise, nurturing students' skills, and fostering innovation in programming and web design.</p>
              </div>
            </div>
          </div>
          <div className="col-sm">
            <div className="card my-3" style={{ width: "20rem", height: "15rem" }}>
              <div className="card-body">
              <h3 className="card-title" style={{ fontFamily: "Bebas Neue", fontSize: "45px", textAlign: "center", color: "#f5a201", fontWeight: "500", marginTop:"75px"}}>Coming Soon...</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Resume
