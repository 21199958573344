import React, { useState, useRef } from 'react';
import Home from './components/home/Home';
import Navbar from './components/navbar/Navbar';
import About from './components/about/About.js';
import Resume from './components/resume/Resume.js';
import Projects from './components/projects/Projects.js';
import Testimonials from './components/testimonials/Testimonials.js';
import Contact from './components/contact/Contact.js';

import './App.css';
import MobNav from './components/navbar/MobNav';


const App = () => {
  const [activeItem, setActive] = useState(1);
  const portfoBodyRef = useRef(null);

  const handleItemClick = (index) => {
    setActive(index);
  };

  const handleScrollToPortfoBody = () => {
    if (portfoBodyRef.current) {
      portfoBodyRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleScrollToProjects = () => {
    const projectsSection = document.getElementById('projects');
    if (projectsSection) {
      projectsSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleScrollToContact = () => {
    const projectsSection = document.getElementById('contact');
    if (projectsSection) {
      projectsSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div style={{ backgroundColor: "#fff" }}>
      <Home onKnowMoreClick={handleScrollToPortfoBody} />
      <div className="portfo-body" ref={portfoBodyRef}>
        <div className="portfo-left">
          <Navbar onItemClick={handleItemClick} activeItem={activeItem} onKnowMoreClick={handleScrollToProjects} />

        </div>
        <div className="navForMobile">
          <MobNav onItemClick={handleItemClick} activeItem={activeItem} onKnowMoreClick={handleScrollToProjects} />
        </div>
        <div className="portfo-right">
          {activeItem === 1 && <About />}
          {activeItem === 2 && <Resume />}
          {activeItem === 4 && <Testimonials />}
        </div>
      </div>

      <div className="projects" id='projects'>
        <Projects onContactClick={handleScrollToContact}/>
      </div>
      <div className="contactBox" id='contact'>
        <Contact />
      </div>
    </div>
  );
};

export default App;
