import React from 'react'

import '../../static/home.css'
import HomeIntro from './HomeIntro'
import ImgBox from './ImgBox'


const Home = ({onKnowMoreClick}) => {
  return (
    <div className='home-parent-container container'>
        <div className="home-left">
            {/* <h1>This is for Text</h1> */}
            <HomeIntro onKnowMoreClick= {onKnowMoreClick}/>
        </div>
        <div className="home-right">
            <ImgBox/>
        </div>
    </div>
  )
}

export default Home
