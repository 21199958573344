import React from 'react'
import LogoImg from './LogoImg'

import '../../static/navbar.css'

const Navbar = ({ onItemClick, activeItem, onKnowMoreClick}) => {

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  return (
    <div className='navbar-container'>
      <LogoImg />
      <div className="navbar" style={{ height: "480px", backgroundColor: "#f5a201", display: "flex", alignItems: "center", justifyContent: "center" }}>
        <ul style={{ display: "flex", flexDirection: "column", justifyContent: "space-evenly", height: "100%", listStyleType: "none", fontSize: "28px", fontFamily: "Kanit", fontWeight: "700", textAlign: "left" }}>
        <li onClick={() => { scrollToTop(); }} className={activeItem === 0 ? 'active' : ''}>Home</li>
          <li onClick={() => onItemClick(1)} className={activeItem === 1 ? 'active' : ''}>About</li>
          <li onClick={() => onItemClick(2)} className={activeItem === 2 ? 'active' : ''}>Resume</li>
          {/* <li onClick={() => onItemClick(3)} className={activeItem === 3 ? 'active' : ''}>Projects</li> */}
          {/* <li onClick={() => onItemClick(4)} className={activeItem === 4 ? 'active' : ''}>Testimonials</li> */}
          <li onClick={onKnowMoreClick} className={activeItem === 5 ? 'active' : ''}>Know More</li>
        </ul>
      </div>
    </div>
  )
}

export default Navbar
