import React from 'react'
import profile from '../../static/assets/profile.jpeg'

const ImgBox = () => {
  return (
    <div className='home-image' style={{height:"100%", width:"100%", display:"flex", alignItems:"center"}}>
        <img src={profile} style={{}} alt="Profile"></img>
    </div>
  )
}

export default ImgBox
