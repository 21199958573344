import React from 'react'
import Tripsy from '../../static/assets/projectLogos/ttLogo.png'
import RedStore from '../../static/assets/projectLogos/redLogo.png'
import Turf from '../../static/assets/projectLogos/turfLogo.jpg'
import SF from '../../static/assets/projectLogos/sfLogo.jpg'
import Mango from '../../static/assets/projectLogos/mangoLogo.png'

import '../../static/projects.css'

const Projects = ({onContactClick}) => {
  return (
    <div style={{ height: "800px" }} className="my-projects">
      <div className="prj-header">
        <h2>My Projects</h2>
      </div>
      <div className="prj-subheader" style={{textAlign:"center", display:"flex", alignItems:"center", justifyContent:"center"}}>
        <h4 style={{backgroundColor:"rgba(255,255,255,0.2)", borderRadius:"15px", boxShadow:"5px 5px 10px #000", textAlign:"center"}}>Some of my recent projects...</h4>
      </div>
      <div className="project-body my-5">
        <div className="container text-center" >
          <div className="prj-row1" style={{}}>
            <div className="">
              <div className="card prj-card" style={{ width: "18rem", overflow: "hidden", display: "flex", alignItems: "center"}}>
                <img src={Tripsy} className="card-img-top" alt="..." style={{ backgroundColor: "#fff" }} />
                <div className="card-body" style={{ backgroundColor: "" }}>
                  <h5 className="card-title" style={{ color: "#fff", fontFamily: "Bebas Neue", fontSize: "38px", fontWeight: "700", textShadow: "2px 2px 2px #000" }}>Tripsy Trips</h5>
                  <p className="card-text" style={{ color: "#fff" }}>Excellent project developed for a travel agency in Pune</p>
                  <a href="https://www.tripsytrips.com" className="btn" target="_blank" rel="noreferrer" style={{ backgroundColor: "#f5a201", boxShadow: "4px 4px 10px #000", fontStyle: "Dosis", fontWeight: "700", color: "#fff" }}>Go to Project</a>
                </div>
              </div>
            </div>
            <div className="">
              <div className="card" style={{ width: "18rem", overflow: "hidden", display: "flex", alignItems: "center" }}>
                <img src={RedStore} className="card-img-top" alt="..." style={{ backgroundColor: "#fff",height:"115px"}} />
                <div className="card-body" style={{ backgroundColor: "" }}>
                  <h5 className="card-title" style={{ color: "#fff", fontFamily: "Bebas Neue", fontSize: "34px", fontWeight: "700", textShadow: "2px 2px 2px #000" }}>Red Store</h5>
                  <p className="card-text" style={{ color: "#fff" }}>Amazing E-commerce website for a clothing brand.</p>
                  <a href="https://ecomm-redstore.netlify.app/" className="btn" target="_blank" rel="noreferrer" style={{ backgroundColor: "#f5a201", boxShadow: "4px 4px 10px #000", fontStyle: "Dosis", fontWeight: "700", color: "#fff" }}>Go to Project</a>
                </div>
              </div>
            </div>
            <div className="">
              <div className="card" style={{ width: "18rem", overflow: "hidden", display: "flex", alignItems: "center" }}>
                <img src={Turf} className="card-img-top" alt="..." style={{ backgroundColor: "#fff", height:"125px"}} />
                <div className="card-body" style={{ backgroundColor: "" }}>
                  <h5 className="card-title" style={{ color: "#fff", fontFamily: "Bebas Neue", fontSize: "38px", fontWeight: "700", textShadow: "2px 2px 2px #000" }}>Turf Booking</h5>
                  <p className="card-text" style={{ color: "#fff" }}>Web application for turf owners to computerise their business.</p>
                  <a href="https://turf.pythonanywhere.com/" className="btn" target="_blank" rel="noreferrer" style={{ backgroundColor: "#f5a201", boxShadow: "4px 4px 10px #000", fontStyle: "Dosis", fontWeight: "700", color: "#fff" }}>Go to Project</a>
                </div>
              </div>
            </div>
          </div>
          <div className="prj-row2 my-5" style={{}}>
            <div className="">
              <div className="card prj-card" style={{ width: "18rem", overflow: "hidden", display: "flex", alignItems: "center"}}>
                <img src={SF} className="card-img-top" alt="..." style={{ backgroundColor: "#fff", height:"120px", aspectRatio:"3/2"}} />
                <div className="card-body" style={{ backgroundColor: "" }}>
                  <h5 className="card-title" style={{ color: "#fff", fontFamily: "Bebas Neue", fontSize: "38px", fontWeight: "700", textShadow: "2px 2px 2px #000" }}>Shailaja's Fitness</h5>
                  <p className="card-text" style={{ color: "#fff" }}>Budget portfolio webiste for fitness center.</p>
                  <a href="https://shailsfit.in/" className="btn" target="_blank" rel="noreferrer" style={{ backgroundColor: "#f5a201", boxShadow: "4px 4px 10px #000", fontStyle: "Dosis", fontWeight: "700", color: "#fff" }}>Go to Project</a>
                </div>
              </div>
            </div>
            <div className="">
              <div className="card" style={{ width: "18rem", overflow: "hidden", display: "flex", alignItems: "center" }}>
                <img src={Mango} className="card-img-top" alt="..." style={{ backgroundColor: "#fff"}} />
                <div className="card-body" style={{ backgroundColor: "" }}>
                  <h5 className="card-title" style={{ color: "#fff", fontFamily: "Bebas Neue", fontSize: "34px", fontWeight: "700", textShadow: "2px 2px 2px #000" }}>Mango Apparel</h5>
                  <p className="card-text" style={{ color: "#fff" }}>Landing page for clothing brand.</p>
                  <a href="https://mango-apparell.netlify.app/" className="btn" target="_blank" rel="noreferrer" style={{ backgroundColor: "#f5a201", boxShadow: "4px 4px 10px #000", fontStyle: "Dosis", fontWeight: "700", color: "#fff" }}>Go to Project</a>
                </div>
              </div>
            </div>
            {/* <div className="">
              <div className="card" style={{ width: "18rem", overflow: "hidden", display: "flex", alignItems: "center" }}>
                <img src={Turf} className="card-img-top" alt="..." style={{ backgroundColor: "#fff", height:"125px"}} />
                <div className="card-body" style={{ backgroundColor: "" }}>
                  <h5 className="card-title" style={{ color: "#fff", fontFamily: "Bebas Neue", fontSize: "38px", fontWeight: "700", textShadow: "2px 2px 2px #000" }}>Turf Booking</h5>
                  <p className="card-text" style={{ color: "#fff" }}>Web application for turf owners to computerise their business.</p>
                  <a href="https://turf.pythonanywhere.com/" className="btn" target="_blank" rel="noreferrer" style={{ backgroundColor: "#f5a201", boxShadow: "4px 4px 10px #000", fontStyle: "Dosis", fontWeight: "700", color: "#fff" }}>Go to Project</a>
                </div>
              </div>
            </div> */}
          </div>
        </div>
        <div className="cont-btn">
          <h4 onClick={onContactClick}>Get in Touch</h4>
        </div>
      </div>
    </div>
  )
}

export default Projects
