import React from 'react'

const Testimonials = () => {
  return (
    <div style={{height:"100vh"}}>
      This is Testimonials Section
    </div>
  )
}

export default Testimonials
