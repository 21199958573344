import React from 'react';
import '../../static/home.css';

const HomeIntro = ({ onKnowMoreClick }) => {
    return (
        <div className='home-text'>
            <h3 style={{ fontFamily: "Protest Strike", fontWeight: "400", fontStyle: "normal", letterSpacing: "2px" }}>HI THERE!</h3>
            <h1 style={{ fontFamily: "Protest Strike", fontWeight: "400", fontStyle: "normal" }}>I'M <span style={{ fontFamily: "Big Shoulders Inline Text", fontWeight: "900", fontStyle: "normal", color: "#f5a201", letterSpacing: "5px" }}>ASHISH NAIR</span></h1>
            <h4 style={{ backgroundColor: "#ffb800", padding: "15px", fontFamily: "Protest Strike", borderRadius: "10px", boxShadow: "5px 5px 15px #000" }}>Full Stack Developer and Python Expert</h4>
            <p style={{margin:"40px 10px", textAlign:"justify", fontSize:"18px"}}>I'm passionate about delivering innovative digital solutions. Our hardworking team embraces challenges and is committed to continuous learning. We take on complex projects to enhance our skills, offering bespoke website design, engaging social media strategies, and impactful graphic design to elevate your brand’s online presence.</p>

            <h4 
                style={{ backgroundColor: "#ffb800", textAlign: "center", padding: "10px 20px", fontSize: "28px", fontWeight: "700", fontFamily: "Bebas Neue", borderRadius: "15px", boxShadow: "5px 5px 15px #000", cursor: "pointer" }} 
                onClick={onKnowMoreClick}
            >
                Know more
            </h4>
        </div>
    )
}

export default HomeIntro;
