import React, { useState, useEffect } from 'react';
import '../../static/about.css';

const About = () => {
  const [years, setYears] = useState(0);
  const [projects, setProjects] = useState(0);
  const [clients, setClients] = useState(0);
  const maxYears = 5;
  const maxProjects = 10;
  const maxClients = 5;

  useEffect(() => {
    const interval = setInterval(() => {
      // Increment values until they reach the maximum
      if (years < maxYears) {
        setYears(years + 1);
      }
      if (projects < maxProjects) {
        setProjects(projects + 1);
      }
      if (clients < maxClients) {
        setClients(clients + 1);
      }
    }, 200); // Adjust the interval as per your preference

    // Clear interval on component unmount
    return () => clearInterval(interval);
  }, [years, projects, clients, maxYears, maxProjects, maxClients]);

  return (
    <div style={{ background: '#282c35' }} className="about-me">
      <div className="about-header">
        <h2>ABOUT ME</h2>
      </div>
      <div className="about-body">
        <div className="about-text-top">
          <h4>
            I'm <b>Ashish Nair</b>, MERN Stack Developer, Python Expert, and Freelancer
          </h4>
          <p>
            As a fervent software developer, I embody a relentless pursuit of knowledge and
            advancement. I approach challenges with enthusiasm, recognizing them as opportunities to
            refine my expertise. Driven by an unwavering work ethic, I eagerly embrace new
            information, understanding the ever-evolving nature of technology demands perpetual
            learning. My dedication to growth fuels my readiness to undertake formidable tasks,
            knowing that each presents a chance for skill augmentation and personal development. I
            firmly believe that in the dynamic realm of software development, one must continuously
            expand their horizons to stay relevant and impactful, and I eagerly embrace this
            journey of perpetual learning and improvement.
          </p>
        </div>
        <div className="about-text-bottom">
          <div className="about-bottom-left">
            <div className="about-grid-top">
              <div className="about-years">
                <h3><span>{years}+</span> <br/> Years</h3>
              </div>
              <div className="about-projects">
                <h3><span>{projects}+</span> <br/> Projects</h3>
              </div>
            </div>
            <div className="about-grid-bottom">
              <div className="about-clients">
                <h3><span>{clients}+</span> <br/> Happy Clients</h3>
              </div>
            </div>
          </div>
          <div className="about-bottom-right">
            <h2>What I Do?</h2>
            <ul>
              <li>1. Full Stack Development (MERN)</li>
              <li>2. Python Project Development</li>
              <li>3. Front-end Development</li>
              <li>4. Website Deployment</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
