import React from 'react'
import Icon from '../../static/assets/contactIcon.webp'

import '../../static/contact.css'

const Contact = () => {
  return (
    <div style={{height:"800px"}} className="my-contact">
      <div className="prj-header">
        <h2>Contact</h2>
      </div>

      <div className="contact-body">
        <div className="left">
            <img src={Icon} alt="" />
        </div>
        <div className="right">
          <h4>Let's get in touch...</h4>

          <ul >
            <li>
            <i class="fa-solid fa-phone-volume"></i>
            <a href="tel: +91 7558317285" target="_blank" rel='noreferrer'>+91 7558317285</a>
            </li>
            <li>
            <i class="fa-solid fa-envelope"></i>
            <a href="mailto: info@ashishnair.in" target="_blank" rel='noreferrer'>info@ashishnair.in</a>
            </li>
            <li>
            <i class="fa-brands fa-linkedin"></i>
            <a href="https://www.linkedin.com/in/ashish-nair-52b084216" target="_blank" rel='noreferrer'>Ashish Nair</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default Contact
